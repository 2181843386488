import { useState } from "react";
import ExportResultsForm from "../ExportResultsForm";

function ExportResultsPage(props) {
    const [serverResponse, setServerResponse] = useState(""); // State for server response
    const serverUrl = process.env.REACT_APP_SERVER_URL;

    function handleFormSubmit(inputData) {
        fetch(serverUrl + "/rest/exportResults/" + props.raceId, {
            method: "POST",
            body: JSON.stringify(inputData),
            headers: { "Content-Type": "application/json" },
        })
            .then((response) => response.json())
            .then((data) => {
                setServerResponse(JSON.stringify(data, null, 2)); // Update response text-area with formatted JSON
            })
            .catch((error) => {
                setServerResponse(`Error: ${error.message}`);
            });
    }

    return (
        <div className="mx-3">
            <h3>Export av resultat till SportTA</h3>
            <ExportResultsForm onSubmit={handleFormSubmit} />
            <div className="mt-4">
                <label htmlFor="serverResponse" className="form-label">
                    Json-resultat till SportTA:
                </label>
                <textarea
                    id="serverResponse"
                    className="form-control"
                    rows="10"
                    readOnly
                    value={serverResponse} // Display server response
                />
            </div>
        </div>
    );
}

export default ExportResultsPage;
