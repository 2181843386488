import { useState, useEffect} from "react";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";


function ResultsPage(props) {
  const [results, setResults] = useState([]);
  const [includeRounds, setIncludeRounds] = useState(false);
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [queryParams] = useSearchParams();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  
  function toDateString(seconds) {
    if (seconds === 0) {
      return "-"
    }
    if (seconds < 0) {
      return "-" + new Date(-seconds * 1000).toISOString().substr(14, 5)  
    }
    return new Date(seconds * 1000).toISOString().substr(14, 5) 
  }

  function fetchAndUpdate(raceId) {
    console.log("Fetching results for raceId: " + raceId);
    setUpdating(true);
    const url = `${serverUrl}/rest/results/${raceId}?_=${new Date().getTime()}`;
    fetch(url, {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      }
    })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setResults(data);
          setUpdating(false);
        });
  }

  const handleChangeIncludeRounds = () => {
    setIncludeRounds(!includeRounds);
  };

  const handleChangeAnalysis = () => {
    setShowAnalysis(!showAnalysis);
  };

  useEffect(() => {
    console.log("raceId queryparam: " + queryParams.get("raceId"));
    let raceId = props.raceId ? props.raceId : queryParams.get("raceId");
    fetchAndUpdate(raceId);

    let counter = 0;
    const interval = setInterval(() => {
      if (counter < 2160) {
        fetchAndUpdate(raceId);
        counter++;
      } else {
        clearInterval(interval);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [props.raceId]);

  return (
    <div className="mx-3">
      <div className="container-full">
        <div className="row">
          <h2 className="col-10">Preliminära Resultat</h2>
          {updating && <p className="col-2 text-responsive-sm">Uppdaterar...</p>}
        </div>
      </div>
      <div className="form-check">
        <label className="form-check-label">Visa åkta straffrundor</label>
        <input className="form-check-input" type="checkbox" onChange={handleChangeIncludeRounds} />
      </div>
      <div className="form-check">
        <label className="form-check-label">Visa analyssida</label>
        <input className="form-check-input" type="checkbox" onChange={handleChangeAnalysis} />
      </div>
      {results.klassResults && !showAnalysis &&
        results.klassResults.map((klassResult) => {
          return (
            <div>
              <p className="mt-4 mb-1 text-responsive-lg fw-bold" key={klassResult.klassName}>
                {klassResult.klassName}
              </p>
              <table className="table table-sm table-hover">
                <thead>
                <tr className="row m-0">
                  <th className={"col-1 text-end me-2 thin-col text-responsive-sm"}> Plac</th>
                  <th className={"col-1 text-end me-2 thin-col text-responsive-sm"}> Nr</th>
                  <th className={"col-2 text-responsive-sm"}> Namn</th>
                  <th className={"col-2 text-responsive-sm"}> Klubb</th>
                  <th className={"col text-responsive-sm"}> L</th>
                  <th className={"col text-responsive-sm"}> S</th>
                  {includeRounds && <th className={"col text-responsive-sm"}> Rundor</th>}
                  <th className={"col text-responsive-sm"}> Åktid</th>
                  <th className={"col text-responsive-sm"}> Tillägg</th>
                  <th className={"col text-responsive-sm"}> Totaltid</th>
                  <th className={"col text-responsive-sm"}> Diff</th>
                  <th className={"col text-responsive-sm"}> Kommentar</th>
                </tr>
                </thead>
                <tbody>
                {klassResult.participants.map((participant) => {
                  return (
                      <tr className="row m-0" key={participant.bib}>
                        <td className={"col-1 text-end me-2 thin-col text-responsive-sm"}>{participant.place}</td>
                        <td className={"col-1 text-end me-2 thin-col text-responsive-sm"}>{participant.bib}</td>
                        <td className={"col-2 text-responsive-sm"}>{participant.name}</td>
                        <td className={"col-2 text-responsive-sm"}>{participant.club}</td>
                        <td className={"col text-responsive-sm"}>{participant.status !== "DSQ" ? participant.misses : "-"}</td>
                        <td className={"col text-responsive-sm"}>{participant.status !== "DSQ" ? participant.missesStanding : "-"}</td>

                        {includeRounds && !participant.diffMissesRounds && (
                            <td className={"col text-responsive-sm"}>{(participant.status === "DSQ") ? "-" : participant.penaltyRounds}</td>
                        )}
                        {includeRounds && participant.diffMissesRounds && (
                            <td className="col text-responsive-sm text-danger fw-bold">{(participant.status === "DSQ") ? "-" : participant.penaltyRounds}</td>
                        )}
                        <td className={"col text-responsive-sm"}>
                          {(klassResult.hideTimes || participant.status === "DSQ") ? "-" : participant.raceTime}
                        </td>
                        <td className={"col text-responsive-sm"}>
                          {(klassResult.hideTimes || participant.status === "DSQ") ? "-" : toDateString(participant.additionalTime)}
                        </td>
                        <td className={"col text-responsive-sm"}>
                          {(klassResult.hideTimes || participant.status === "DSQ") ? "-" : participant.totalTime}
                        </td>
                        <td className={"col text-responsive-sm"}>
                          {(klassResult.hideTimes || participant.status === "DSQ") ? "-" : participant.timeAfterLeader}
                        </td>
                        <td className={"col text-responsive-sm"}>{participant.status + " " + participant.comment}</td>
                      </tr>)
                })}
                </tbody>
              </table>
            </div>
          );
        })}

      {results.klassResults && showAnalysis &&
          results.klassResults.map((klassResult) => {
            return (
                <div>
                  <p className="mt-4 mb-1 text-responsive-lg fw-bold" key={klassResult.klassName}>
                    {klassResult.klassName}
                  </p>
                  <table className="table table-sm table-hover">
                    <thead>
                    <tr className="row m-0">
                      <th className={"col-1 text-end me-2 thin-col text-responsive-sm"}> Plac</th>
                      <th className={"col-1 text-end me-2 thin-col text-responsive-sm"}> Nr</th>
                      <th className={"col-2 text-responsive-sm"}> Namn</th>
                      <th className={"col-1 text-responsive-sm"}></th>
                      <th className={"col-1 text-responsive-sm text-end "}>Varv 1</th>
                      <th className={"col-1 text-responsive-sm text-end "}>Varv 2</th>
                      <th className={"col-1 text-responsive-sm text-end "}>Varv 3</th>
                      <th className={"col-1 text-responsive-sm text-end "}>Varv 4</th>
                      <th className={"col-1 text-responsive-sm text-end "}>Varv 5</th>
                      <th className={"col-1 text-responsive-sm text-end "}>Summa</th>
                      <th className={"col-1 text-responsive-sm text-end "}>Totaltid</th>
                    </tr>
                    </thead>
                    <tbody>
                    {klassResult.participants.map((participant) => {
                      return (
                          <tr className="row m-0" key={participant.bib}>
                            <td className={"col-1 text-end me-2 thin-col text-responsive-sm"}>{participant.place}</td>
                            <td className={"col-1 text-end me-2 thin-col text-responsive-sm"}>{participant.bib}</td>
                            <td className={"col-2 text-responsive-sm"}>{participant.name}</td>
                            <td className={"col-1 text-responsive-sm"}>
                              <div className={"row"}>Åktid</div>
                              <div className={"row"}>Valltid:</div>
                              <div className={"row"}>Tillägg</div>
                            </td>
                            {participant.rangeTimes.rangeAndSplitTimes.map((rangeAndSplitTime) => {
                              return (
                                  <td className={"col-1 text-end text-responsive-sm"}>
                                    <div>
                                      {toDateString(rangeAndSplitTime.splitTime)}
                                    </div>
                                    <div>
                                      {toDateString(rangeAndSplitTime.rangeTime)}
                                    </div>
                            </td>
                                )})}
                            <td className={"col-1 text-end text-responsive-sm"}>
                              <div>
                                {toDateString(participant.rangeTimes.sumOfLapTimes)}
                              </div>
                              <div>
                                {toDateString(participant.rangeTimes.sumOfRangeTimes)}
                              </div>
                              <div>
                                {toDateString(participant.additionalTime)}
                              </div>
                            </td>
                            <td className={"col-1 text-end text-responsive-sm"}>{participant.totalTime}</td>
                          </tr>)
                    })}
                    </tbody>
                  </table>
                </div>
            );
          })}

      {results && results.race && results.race._id &&
          <a href={"http://results.biathlontiming.se/?raceId=" + results.race._id} target="_blank"
             rel="noopener noreferrer">Länk till publik resultatsida</a>}
      <br/>
      {results && results.race && results.race._id && (
          <Link to={`/exportResults`}>
            Exportera resultat till SportTA
          </Link>
      )}
    </div>
  );
}

export default ResultsPage;
