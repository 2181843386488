import { useRef } from "react";

function ExportResultsForm({ onSubmit }) {
    const textRef = useRef();

    function submitHandler(event) {
        event.preventDefault();

        try {
            // Parse the JSON string from the textarea
            const parsedData = JSON.parse(textRef.current.value);

            // Pass the parsed JSON object to the parent component
            onSubmit(parsedData);
        } catch (error) {
            // Handle JSON parsing errors
            console.error("Invalid JSON:", error);
            alert("Please enter a valid JSON string");
        }
    }

    return (
        <form onSubmit={submitHandler}>
            <div className="mb-3 mt-4">
                <label htmlFor="inputText" className="form-label">
                    I SportTA, Exportera - För import i annat system. Klistra in här:
                </label>
                <textarea
                    id="inputText"
                    className="form-control"
                    rows="5"
                    ref={textRef}
                />
            </div>
            <button className="btn btn-primary" type="submit">
                Skapa resultat
            </button>
        </form>
    );
}

export default ExportResultsForm;